<template>
  <div class="position-relative full-width">
    <v-tabs v-model="tab" color="primary" :slider-size="3">
      <v-tab>
        {{ $t('vendor.onboarding.businessGeneralInfo') }}
      </v-tab>
      <v-tab>{{ $t('vendor.onboarding.vendorClassification') }}</v-tab>
      <v-tab>{{ $t('vendor.onboarding.documents') }}</v-tab>
      <v-tab>{{ $t('vendor.onboarding.vendorAdditionalDetails') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item><business-details v-model="form" readonly /></v-tab-item>
      <v-tab-item
        ><business-classification
          v-model="form"
          readonly
          :primary-categories="primaryCategories"
          :secondary-categories="secondaryCategories"
      /></v-tab-item>
      <v-tab-item>
        <business-docs v-model="form" readonly />
      </v-tab-item>
      <v-tab-item>
        <additional-details v-model="form" readonly />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'OnBoardingSummery',
  components: {
    BusinessDetails: () => import('./OnBoardingBusinessDetails.vue'),
    BusinessClassification: () =>
      import('./OnBoardingBusinessClassification.vue'),
    BusinessDocs: () => import('./OnBoardingDocuments.vue'),
    AdditionalDetails: () => import('./OnBoardingAdditionalDetails.vue')
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    primaryCategories: {
      type: Array,
      default: () => []
    },
    secondaryCategories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tab: 0
    };
  }
};
</script>

<style></style>
